<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="تعديل مادة">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم</label>
                <b-form-input v-model="name" type="text" id="input-title" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="code"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الكود</label>
                <b-form-input v-model="code" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
             <b-col cols="4">
                      <b-form-group
                        label=" النوع "
                        label-for="payment-method"
                      >
                        <v-select

                          v-model="type"
                          :options="orderOption"
                          :reduce="(val) => val.value"
                          input-id="payment-method"
                          class="payment-selector"
                          :clearable="false"
                         
                        />
                      </b-form-group>
                    </b-col>
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="brand"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> اسم الماركة</label>
                <b-form-input v-model="brand_name" type="text" id="input-brand_name" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="en_name"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم باللغة الانجليزية</label>
                <b-form-input v-model="en_name" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="employe"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> التصنيف</label>
                <v-select
                  v-model="item_category_id"
                  label="name"
                  :options="optioneItemCategory"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="UpdateItemCategory">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل المادة</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      name: "",
      code:'',
      brand_name:'',
      optioneItemCategory:[],
      en_name: "",
      item_category_id:'',
      type:'',
        orderOption :[
      { label: 'سلعة ', value: 0 },
      { label: 'خدمة ', value: 1 },
   

    ],
      
      searchTerm: "",
      id: "",
   
    };
  },

  created() {
    this.getItem()
    this.getItemCategory();
  },

  methods: {
    getItemCategory() {
      this.optioneItemCategory=[]
      this.$http.get("/api/v1/item-category").then((res) => {
        //console.log(res);
  
       this.optioneItemCategory=res.data
      });
    },
 
    UpdateItemCategory() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data =[]
            
                data = {
        en_name: this.en_name,
        code: this.code,
        name:this.name,
        brand_name:this.brand_name,
        item_category_id:this.item_category_id,
        type:this.type
      };
          
      this.$http.put(`/api/v1/item/${this.$route.params.id}`, data).then((res) => {
        //console.log(res);
        
        this.$swal({
          title: "",
          text: "تمت تعديل المادة بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       
      });
    }})})
    },
    

    getItem() {
      this.$http.get(`/api/v1/item/${this.$route.params.id}`).then((res) => {
        //console.log('rewards', res)
        this.en_name=res.data.data.en_name
         this.code=res.data.data.code
     this.name=res.data.data.name
     this.brand_name=res.data.data.brand_name
     this.item_category_id=res.data.data.item_category_id
     this.type=res.data.data.type
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>